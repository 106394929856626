import $ from "jquery";

$(function() {

    $('.js-tab-button').click(function () {
        var id = $(this).data('id')
        $('.js-tab-button:not([data-id="' + id + '"])').removeClass('is-active')
        $('.js-tab-button[data-id="' + id + '"]').addClass('is-active')
        $('.js-tab:not([data-id="' + id + '"])').attr('hidden', 'hidden')
        $('.js-tab[data-id="' + id + '"]').removeAttr('hidden')
    })
});
