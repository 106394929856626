import $ from "jquery";

$(function() {
    if ($(window).width() > 1024) {
        const pageTop = $(".js-page-top");
        pageTop.hide();
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                pageTop.fadeIn();
            } else {
                pageTop.fadeOut();
            }
        });
        pageTop.click(function () {
            $("body,html").animate(
            {
                scrollTop: 0,
            },
            200
            );
            return false;
        });
        // フッター手前でストップ
        $(".js-page-top").hide();
        $(window).on("scroll", function () {
            const scrollHeight = $(document).height();
            const scrollPosition = $(window).height() + $(window).scrollTop();
            const footHeight = $(".l-footer").innerHeight() + 35;
            if (scrollHeight - scrollPosition <= footHeight) {
                $(".js-page-top").css({
                    position: "absolute",
                    bottom: footHeight,
                });
            } else {
                $(".js-page-top").css({
                    position: "fixed",
                    bottom: "35px",
                });
            }
        });
    }
});
