import '../scss/style.scss';

import $ from 'jquery';
import './lib/spNav';
import './lib/pageTop';
import './lib/modal';
import './lib/gallery';

$(function() {
    $(window).on("resize", function() {
        $(".js-toggle-nav").removeClass("is-open");
        $(".c-sp-nav").removeClass("is-open");
        $("body").removeClass("is-open");
        $(".js-page-top").hide();
    });
});

window.onload = function () {
    // $("body").addClass("onload");
};
