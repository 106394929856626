import $ from "jquery";

$(function() {
    $(".js-modal-open").on("click", function () {
        $(this).next('.js-modal').fadeIn();
        $(this).next('.js-modal').children('.js-modal-close-bg').fadeIn();
        $("body").addClass("is-open ");
        return false;
    });
    $(".js-modal-close-bg").on("click", function () {
        $(this).fadeOut();
        $(".js-modal").fadeOut();
        $("body").removeClass("is-open ");
        return false;
    });
    $(".js-modal-close-button").on("click", function () {
        $(".js-modal-close").fadeOut();
        $(".js-modal").fadeOut();
        $("body").removeClass("is-open ");
        return false;
    });
});
